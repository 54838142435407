.sec-4 {
  h2 {
    color: #111827;
    text-align: center;

    /* Desktop/h3 */
    font-family: 'Lexend';
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px; /* 133.333% */
    margin-bottom: 20px;
    margin-top: 52px;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px; /* 150% */
    }
  }

  .row {
    .col-lg-4 {
      display: flex;

      .box {
        display: flex;
        flex-flow: column;
        flex: 1;
        border-radius: 0px 0px 8px 8px;
        background: var(--Slate-Light-GL2, #f8f9fa);
        /* shadow-base */
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

        .image-wrapper {
          // height: 324px;
          // min-height: 324px;
          // max-height: 324px;
          border-radius: 8px 8px 0px 0px;
          // background: radial-gradient(101.77% 101.77% at 50% 100%, #1e3a8a 0%, #111827 100%);
        }

        .content-wrapper {
          min-height: 400px;
          padding: 32px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          flex: 1;

          h3 {
            color: #111827;

            /* Desktop/h4 */
            font-family: 'Lexend';
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 36px; /* 150% */
          }

          p {
            color: #6b7280;

            /* Desktop/body */
            font-family: 'Lexend';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }

          ul {
            list-style: none;
            padding-left: 0;

            li {
              color: #111827;
              /* Desktop/h6 */
              font-family: 'Lexend';
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                max-width: 16px;
                max-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #bfdbfe;
                border-radius: 4px;
              }

              &:not(:last-child) {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }
}
