@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&;display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Lato');

//-------Color
$backgroundColor: #f8f9fa;
$firstColor: #15192c;
$secondColor: #fbfcfd;
$thirdColor: #4167da;
$forthColor: #687076;
$fifthColor: #11181c;
$sixthColor: #e34a51;
$seventhColor: #eef1fd;
$eightColor: #f7f8fa;
$nineColor: #d7dbdf;
$tenColor: #f0f4ff;
$elevenColor: #169a7d;
$twelveColor: #4167da;
$thirteenColor: #19223f;
$fourteenColor: #859ffc;
$fifteenColor: #19223f;
$sixteenColor: #fdfdfe;
$seventeenColor: #cb2d36;
$eighteenColor: #f8f9fa;
$nineteenColor: #fdb139;
$twentyColor: #3654af;
$twentyOneColor: #697177;
$twentyTwoColor: #f1f3f5;
$twentyThreeColor: #afc1f3;
$twentyFourColor: #111e45;
$twentyFiveColor: #8ea6ec;

//text
$font-weight-light: 400;
$font-weight-normal: 500;
//btn
$btn-border-radius: 7px;
$btn-font-size: 14px;

$btn-padding-y: 10px;
$btn-padding-x: 20px;
$btn-border-radius: 7px;

$btn-padding-y-lg: 10px;
$btn-padding-x-lg: 60px;
$btn-font-size-lg: 14px;
$btn-font-family: 'Roboto';
$btn-color: $seventhColor;
$nav-link-color: $seventhColor;
//form-lable

$form-label-color: $fifthColor;
$display-font-family: 'Roboto';
$form-label-font-size: 12px;
$form-label-font-weight: 700;

//input
$input-font-family: 'Lexend';
$input-font-size: 14px;
$input-font-weight: 500;
$input-color: $fifthColor;
$input-bg: $twentyTwoColor;
$input-border-radius: 8px;
$input-border-width: 1px;
$input-border-color: $nineColor;
$input-padding-y: 10px;
$input-padding-x: 12px;
$input-placeholder-color: #687076;

$card-border-color: $secondColor;
$card-border-radius: 8px;
$h2-font-size: 24px;
$h3-font-size: 18px;

@import '/node_modules/bootstrap/scss/bootstrap';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Site ====
/*#region features*/
.features-icon {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  max-width: 56px;
  max-height: 56px;
  background-color: $sixteenColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*#endregion features*/

/*#region find-best-plan*/
.find-best-plan {
  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }
  // margin-bottom: 300px;
  &-header {
    margin-top: -1px;
    min-height: 300px;
    background: url('https://cdn.1cdn.app/application/LOUISEBOT/23111209461261_plans-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(50% 0%, 100% 0%, 100% 50%, 100% 93.8%, 78.4% 100%, 26.7% 100%, 0% 95.5%, 0% 0%, 0% 0%);
    --bs-secondary-color: $seventhColor;

    @include media-breakpoint-down(sm) {
      min-height: 350px;
    }
  }

  &-body {
    // margin-top: -80px !important;
    position: relative;
    &-items {
      @include media-breakpoint-up(xl) {
        width: 1200px;
        margin: 0px auto !important;
      }
    }
  }
}

/*#endregion RegionName*/

.find-place {
  .container-xl {
    min-height: 870px;
  }
  background: linear-gradient(180deg, #000 0%, #284087 100%), #15192c;

  .header-robot-img {
    position: absolute;
    top: -471px;
    right: 164px;
    @include media-breakpoint-up(md) {
      img {
        width: 405.388px;
        object-fit: contain;
        height: 658.548px;
      }
    }

    @include media-breakpoint-only(md) {
      right: 100px;
    }
  }

  a {
    width: 134px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media-breakpoint-only(md) {
    .header-img {
      padding: 0px 20px;
      left: 0px;
    }

    .container-xl {
      min-height: 725px;
    }
  }

  @media only screen and (max-width: 600px) {
    .header-robot-img {
      position: absolute;
      top: -225px;
      right: 0px;
      img {
        width: 200px;
        height: 300.657px;
        object-fit: contain;
      }
    }

    .container-xl {
      min-height: 580px;
    }
  }
}

.footer-image-svg {
  position: absolute;
  bottom: -1px;
  z-index: 100;
}

.customer-support {
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 20px;
  }
}

.footer-link {
  a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #11181c;
  }
}

.footer-logo {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: #889096;
}

.circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.what-is-chatbox {
  min-height: 430px;
  .container-xl {
    min-height: inherit;
    .row {
      min-height: inherit;

      .what-is-chatbox-left {
        display: flex;
        align-items: center;
        div {
          margin-top: 10px;
          width: 580px;
          height: 326px;
          background: #e6e8eb;
        }
      }

      .what-is-chatbox-right {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          margin-bottom: 8px;
        }
        p {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #889096;
          margin-bottom: 32px;
        }
        button {
          font-weight: 600;
          font-size: 14px;
          color: #687076;
        }
      }
    }
  }
}

.frequently-asked-questions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-top: 20px;
  padding-top: 52px;

  h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #11181c;
  }
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #889096;
  }
  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    &-item {
      height: 88px;
      background: #fbfcfd;
      border-radius: 12px;
      width: 100%;
      padding: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #11181c;
      }

      &-btn {
        width: 50px;
        height: 50px;
        background: #f7f8fa;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
}

.plan-item {
  background: #fbfcfd;
  border: 1px solid #dfe3e6;
  border-radius: 12px;

  &-image {
    background: #f1f3f5;
    border-radius: 12px;
    height: 293px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-header {
      margin-top: 17px;
      margin-bottom: 12px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      color: #11181c;
    }
    &-description {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #11181c;
      margin-bottom: 20px;
    }
    &-description-text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #687076;
    }
    &-section {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h5 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #687076;
      }
      &-box {
        background: #f1f3f5;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        &-item {
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: center;

          &-disable {
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: center;
            color: #687076;
          }
        }
      }
      &-btn {
        width: 100%;
        height: 56px;
        background: #889096;
        border-radius: 8px;
        margin-bottom: 10px;
      }
    }
  }
}

.plan-find {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    color: #11181c;
  }
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #889096;
  }
}

.footer-link {
  a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $seventhColor;
  }
}

.footer-logo {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: $seventhColor;
}

.language {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $seventhColor;
}

.dropdown {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $seventhColor;
}

.copy-right {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $seventhColor;
}

.footer-icon {
  background: $fifteenColor;
  border-radius: 8px;
  width: 36px !important;
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  flex: none !important;
  svg {
    color: $seventhColor;
  }
}

.navbar {
  min-height: 64px;
  font-family: 'Roboto', sans-serif;

  #dropdown-basic {
    display: flex;
    align-items: center;
  }

  .nav-btn {
    border-radius: 6px;
    color: #ffffff;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 110px;
    height: 40px;
    background: $twelveColor;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    @include media-breakpoint-down(lg) {
      &.lst-item {
        width: 100%;
      }
    }
  }

  .menu-icon {
    width: 24px;
    height: 24px;
  }
}

.navbar-brand {
  padding-top: 0;
  margin-right: 50px;
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #889096;
  }
}

.navbar-link {
  a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $seventhColor !important;
  }
}

.nav-btn-light {
  border-radius: 6px !important;
  color: $fourteenColor !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  // width: 110px;
  // height: 40px;
  background: $thirteenColor !important;
  border-radius: 6px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 10px !important;
  padding: 10px;
}

.check-my-account {
  bottom: 10%;
  right: 10%;
}

.send-money {
  top: 10%;
  left: 10%;
}

.accordion-header {
  button {
    background-color: $secondColor;
  }
}

.accordion-button:not(.collapsed) {
  color: unset;
  background-color: $secondColor;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
  z-index: 3;
  border-color: unset;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 68px;
  padding: 10px;
}

.side-authentication {
  &-top {
    background: linear-gradient(220deg, #c7d5f8 0%, #d9e2fb 100%);
  }
  &-bottom {
    fill: linear-gradient(218deg, #d9e2fb 0%, rgba(217, 226, 251, 0) 100%);
  }
}

.navbar-float {
  position: fixed !important;
  width: 100%;
}

.custom-select {
  .css-13cymwt-control {
    div {
      background-color: $twentyTwoColor;
    }
    .css-w9q2zk-Input2 {
      background-color: unset;
    }
    .css-19bb58m {
      background-color: unset;
    }
  }
}

.btn-primary {
  @include button-variant(
    $thirdColor,
    darken($thirdColor, 7.5%),
    $seventhColor,
    lighten($thirdColor, 5%),
    lighten($thirdColor, 10%),
    $seventhColor
  );
  background-color: #4167da;

  border: unset;
  // height: 40px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  border-radius: 10px;
}

.btn-secondary {
  @include button-variant(
    $tenColor,
    darken($tenColor, 7.5%),
    $twentyColor,
    lighten($twentyColor, 5%),
    lighten($twentyColor, 10%),
    $tenColor
  );
  border: unset;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-family: 'Lexend';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.btn-outline-secondary {
  @include button-variant(
    $twentyTwoColor,
    $twentyTwoColor,
    $forthColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $forthColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $forthColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $forthColor
  );
  border: unset;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-family: 'Lexend';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.btn-info {
  @include button-variant(
    $twentyTwoColor,
    $twentyTwoColor,
    $twentyColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $twentyColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $twentyColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $twentyColor
  );
  border: unset;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.btn-outline-primary {
  @include button-outline-variant($thirdColor, $thirdColor, $firstColor, $thirdColor);
  border: unset;
  background-color: $firstColor;
  height: 40px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}

.btn-outline-default {
  @include button-outline-variant($seventhColor, $seventhColor, $firstColor, $seventhColor);
  border: unset;
  background-color: $firstColor;
  height: 40px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}

.btn-danger {
  @include button-variant(
    $twentyTwoColor,
    $twentyTwoColor,
    $seventeenColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $seventeenColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $seventeenColor,
    $twentyTwoColor,
    $twentyTwoColor,
    $seventeenColor
  );
  border: unset;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-family: 'Lexend';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.btn-outline-danger {
  @include button-outline-variant($sixthColor, $sixthColor, $firstColor, $sixthColor);
  border: unset;
  background-color: $firstColor;
  height: 40px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}

.form-label {
  font-family: 'Roboto';
}

h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #11181c;
}

h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: #11181c;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
  // color: unset;
}

.fs-6 {
  font-size: 12px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
}

.fs-5 {
  font-size: 14px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
}

.fs-4 {
  font-size: 16px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
}
.fs-3 {
  font-size: 18px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
}

.fs-2 {
  font-size: 24px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
}

.text-light {
  --bs-text-opacity: 0 !important;
  font-style: normal;
  font-weight: 400 !important;
}

.form-select {
  padding: 13px 12px;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: $seventhColor;
  background-color: #d7dbdf;
  background-clip: padding-box;
  border: 1px solid #f1f3f5;
  appearance: none;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-sm {
  //height: 32px;
  text-wrap: nowrap;
  padding: 6px 12px;
}

.form-select-sm {
  height: 32px;
  padding: 6px 32px 6px 8px;
}

.select__control {
  padding: 5px 12px;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: #11181c !important;
  background-color: #d7dbdf !important;
  background-clip: padding-box;
  border: 1px solid #f1f3f5 !important;
  appearance: none;
  border-radius: 8px;
}

.input-backgroud-color {
  background-color: $nineColor;
}

.text-success {
  color: $thirdColor !important;
}

.text-gray {
  color: $seventhColor !important;
}

.bg-firstColor {
  background-color: $firstColor;
}
.bg-eightColor {
  background-color: $eightColor !important;
}
.bg-secondColor {
  background-color: $secondColor;
}
.bg-thirdColor {
  background-color: $thirdColor;
}
.bg-tenColor {
  background-color: $tenColor !important;
}
.bg-twentyTwoColor {
  background-color: $twentyTwoColor !important;
}
.bg-witheColor {
  background-color: #ffffff !important;
}
.color-thirdColor {
  color: $thirdColor !important;
}

.color-seventhColor {
  color: $seventhColor !important;
}

.color-twelveColor {
  color: $twelveColor !important;
}

.color-seventeenColor {
  color: $seventeenColor !important;
}

.color-sevenColor {
  color: $seventhColor !important;
}
.bg-sevenColor {
  background-color: $seventhColor !important;
}

.color-twentyOneColor {
  color: $twentyOneColor !important;
}

.color-forthColor {
  color: $forthColor !important;
}

.color-fourteenColor {
  color: $fourteenColor !important;
}

.color-twentyThreeColor {
  color: $twentyThreeColor !important;
}
.color-twentyFourColor {
  color: $twentyFourColor !important;
}
.color-twentyFiveColor {
  color: $twentyFiveColor !important;
}
.color-twentyColor {
  color: $twentyColor !important;
}

.color-sixteenColor {
  color: $sixteenColor !important;
}
.color-fifthColor {
  color: $fifthColor !important;
}
.color-twentyTwoColor {
  color: $twentyTwoColor !important;
}
.bg-twelveColor {
  background-color: $twelveColor;
}
.bg-nineColor {
  background-color: $nineColor;
}

.bg-fifthColor {
  background-color: $fifthColor !important;
}

.bg-fifteenColor {
  background-color: $fifteenColor;
}
.bg-thirteenColor {
  background-color: $thirteenColor;
}

.bg-sixteenColor {
  background-color: $sixteenColor;
}
.bg-eighteenColor {
  background-color: $eighteenColor;
}
.color-eighteenColor {
  color: $eighteenColor;
}

.color-nineteenColor {
  color: $nineteenColor;
}

.font-poppins {
  font-family: Poppins;
}

.font-sofiaSans {
  font-family: 'Lexend';
}

.fw-light {
  font-weight: 400 !important;
}

// h1 {
//   font-size: 36px;
// }

.PasswordStrengthBar {
  padding: 0px 8px !important;
  div {
    margin: 0px !important;
  }
  p {
    display: none;
  }
  div > div:nth-child(even) {
    width: 0px !important;
  }
}

.form-select:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.full-height {
  height: 100% !important;
  min-height: calc(100vh - 80px) !important;
}

.border-dotted {
  border-style: dashed !important;
  border-color: #4167da;
}

.cursor-pointer {
  cursor: pointer;
}

.orgchart {
  background-image: unset !important;
  background-color: white;
}

.orgchart.myChart > ul > li > ul li::before {
  border-top-color: #c1c8cd;
}

.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #c1c8cd;
}

.orgchart ul li .oc-node:hover {
  background-color: unset !important;
}

.orgchart ul li .oc-node.selected {
  background-color: unset !important;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.not-found-page {
  $white: #4167da;
  $gray: #4167da;
  $dark: #4167da;
  $light: #4167da;

  $big: 'Lexend';
  $body: 'Lexend';

  .container {
    margin: 0 auto;
    position: relative;
    width: 250px;
    height: 250px;
    margin-top: -40px;
    min-height: 70vh;
  }

  .ghost {
    width: 50%;
    height: 53%;
    left: 20%;
    top: 10%;
    position: absolute;
    border-bottom: none;
    animation: float 2s ease-out infinite;
    img {
      width: 150px;
      height: 150px;
    }
  }

  .one,
  .two,
  .three,
  .four {
    position: absolute;
    background: $white;
    top: 85%;
    width: 25%;
    height: 23%;
    border: 1px solid $gray;
    z-index: 0;
  }

  .one {
    border-radius: 0 0 100% 30%;
    left: -1px;
  }

  .two {
    left: 23%;
    border-radius: 0 0 50% 50%;
  }

  .three {
    left: 50%;
    border-radius: 0 0 50% 50%;
  }

  .four {
    left: 74.5%;
    border-radius: 0 0 30% 100%;
  }

  .shadow {
    position: absolute;
    width: 30%;
    height: 7%;
    background: $gray;
    left: 35%;
    top: 45%;
    border-radius: 50%;
    animation: scale 2s infinite;
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes float {
    50% {
      transform: translateY(15px);
    }
  }

  h1 {
    font-family: $big;
    color: $white;
    text-align: center;
    font-size: 9em;
    margin: 0;
    text-shadow: -1px 0 $gray, 0 1px $gray, 1px 0 $gray, 0 -1px $gray;
  }
  h3 {
    font-family: $body;
    font-size: 2em;
    text-transform: uppercase;
    text-align: center;
    color: $gray;
    margin-top: -20px;
    font-weight: 900;
  }
  p {
    text-align: center;
    font-family: $body;
    color: $dark;
    font-size: 0.6em;
    margin-top: -20px;
    text-transform: uppercase;
  }
}

.Toastify {
  .Toastify__toast-body {
    padding: 4px;
    margin: 0px;
    border-radius: 10px !important;
  }
  &__toast {
    max-height: 100px !important;
    min-height: 44px !important;
    flex-shrink: 0;
    border-radius: 10px !important;
    background: var(--3, #f1f3f5) !important;
  }
  &__toast-body {
    div {
      color: var(--slate-light-11, #687076);
      font-family: 'Lexend';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .Toastify__close-button {
    align-self: center;
    svg {
      font-size: 24px;
      width: 24px;
      height: 24px;
    }
  }

  .Toastify__progress-bar--success {
    background-color: #37a36e;
  }
  .Toastify__progress-bar--warning {
    background-color: #fdb139;
  }
  .Toastify__progress-bar--error {
    background-color: #e34a51;
  }
}

.sec-2 {
  .swiper-slide {
    width: fit-content !important;
  }
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

textarea.form-control::-webkit-input-placeholder {
  color: #a1a5a7;
}

// Chat
.chat-body {
  height: 100% !important;
  position: fixed !important;
  top: 0px !important;
}
.chat-btn-send {
  button {
    border: none !important;
    background-color: transparent !important;
  }
}

textarea {
  border: none !important;
}

.cancel-record-button {
  border: none !important;
  background-color: transparent !important;
}

.modal-body-footer {
  button {
    border: none !important;
  }
}
.actionButton > div:not(:first-child) {
  display: none;
}

// Welcome page
.welcome-page {
  h1 {
    font-family: 'Lexend';
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
  }

  p {
    font-family: 'Lexend';
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
  }
}

// Responsive
@include media-breakpoint-down('md') {
  .tw-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .find-place {
    .header-robot-img {
      position: absolute;
      top: -200px;
      right: 0px;
    }

    img {
      &:last-child {
        max-width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}

.human-clone {
  background: #f8faff;

  .section-1 {
    span {
      color: #3654af;
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px; /* 133.333% */
      text-transform: uppercase;
    }

    h1 {
      color: var(--Slate-Light-GL12, #11181c);
      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    h2 {
      color: #11181c;
      // text-align: center;
      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      margin-bottom: 8px;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    h3 {
      color: #111827;

      /* Desktop/h2 */
      font-family: 'Lexend';
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 60px; /* 125% */
    }

    p {
      color: #687076;
      font-family: 'Lexend';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }

    ul,
    ol {
      li {
        color: #687076;
        font-family: 'Lexend';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }
}

.human-clone-page,
.text-scenario-chatbot,
.ai-chatbot {
  .sec-1 {
    span {
      &.frs {
        color: #2563eb;

        /* Desktop/h6 */
        font-family: 'Lexend';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }

    h1 {
      color: #f9fafb;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    h2 {
      color: #f9fafb;
    }

    p {
      color: #f9fafb;

      /* Desktop/body */
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .sec-2,
  .sec-6 {
    h2 {
      color: #111827;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      text-align: left;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    p {
      color: #6b7280;
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }

  .sec-3,
  .sec-7 {
    // margin-bottom: 52px;

    h2 {
      color: #111827;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      text-align: left;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    p {
      color: #6b7280;
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }

    .extraBox {
      .iconWrapper {
        width: 64px;
        height: 64px;
        min-width: 64px;
        min-height: 64px;
        max-width: 64px;
        max-height: 64px;
        border-radius: 8px;
        background: #e5e7eb;
      }
    }
  }

  .sec-4 {
    h2 {
      color: #111827;
      text-align: center;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      margin-bottom: 20px;
      margin-top: 52px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px; /* 150% */
      }
    }

    .row {
      .col-lg-4 {
        display: flex;

        .box {
          display: flex;
          flex-flow: column;
          flex: 1;
          border-radius: 0px 0px 8px 8px;
          background: white;
          /* shadow-base */
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

          .image-wrapper {
            // height: 324px;
            // min-height: 324px;
            // max-height: 324px;
            border-radius: 8px 8px 0px 0px;
            // background: radial-gradient(101.77% 101.77% at 50% 100%, #1e3a8a 0%, #111827 100%);
          }

          .content-wrapper {
            min-height: 245px;
            padding: 32px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            flex: 1;

            h3 {
              color: #111827;

              /* Desktop/h4 */
              font-family: 'Lexend';
              font-size: 24px;
              font-style: normal;
              font-weight: 800;
              line-height: 36px; /* 150% */
            }

            p {
              color: #6b7280;

              /* Desktop/body */
              font-family: 'Lexend';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }

            ul {
              list-style: none;
              padding-left: 0;

              li {
                color: #111827;
                /* Desktop/h6 */
                font-family: 'Lexend';
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 142.857% */
                display: flex;
                align-items: center;
                gap: 10px;

                span {
                  width: 16px;
                  height: 16px;
                  min-width: 16px;
                  min-height: 16px;
                  max-width: 16px;
                  max-height: 16px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #bfdbfe;
                  border-radius: 4px;
                }

                &:not(:last-child) {
                  margin-bottom: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .sec-5 {
    h2 {
      color: #111827;
      text-align: center;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      margin-bottom: 20px;
      margin-top: 52px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px; /* 150% */
      }
    }

    .row {
      .col-lg-3 {
        display: flex;

        .box {
          border-radius: 8px;
          background: #f8f9fa;
          display: flex;
          padding: 52px 32px;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          flex: 1;
          /* shadow-base */
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

          .icon-wrapper {
            border-radius: 98px;
            background: radial-gradient(50% 50% at 50% 50%, #1d4ed8 0%, #374151 100%);
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            color: #93c5fd;
            text-align: center;
            /* Desktop/h4 */

            font-size: 34px;
            font-family: 'Noto Color Emoji', sans-serif;
            font-weight: 400;
            font-style: normal;
            line-height: 36px; /* 150% */
          }

          h3 {
            color: #111827;
            text-align: center;
            /* Desktop/h5 */
            font-family: 'Lexend';
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px; /* 133.333% */
            margin: 0 auto 0 auto;
          }

          p {
            color: #6b7280;
            text-align: center;

            /* Desktop/body */
            font-family: 'Lexend';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .sec-8 {
    p {
      color: #111827;
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      margin-bottom: 20px;
    }

    a {
      padding: 9px 17px;
      border-radius: 6px;
      background: #2563eb;
      /* shadow/sm */
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      color: var(--white, #fff);

      /* text-sm/leading-5/font-medium */
      // font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
}

.video-chatbot-page {
  .sec-1 {
    span {
      &.frs {
        color: #2563eb;

        /* Desktop/h6 */
        font-family: 'Lexend';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }

    h1 {
      color: #f9fafb;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    p {
      color: #f9fafb;

      /* Desktop/body */
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .sec-2 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 52px 112px;
    background: #2563eb;

    h2 {
      color: #f9fafb;
      text-align: center;
      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      margin-bottom: 8px;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    p {
      color: #bfdbfe;
      text-align: center;
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      margin-bottom: 32px;
    }

    .box {
      padding: 20px 30px;

      span {
        color: #f9fafb;
        text-align: center;
        /* Desktop/h2 */
        font-family: 'Lexend';
        font-size: 48px;
        font-style: normal;
        font-weight: 800;
        line-height: 60px; /* 125% */
        margin-bottom: 8px;
      }

      p {
        color: #bfdbfe;
        text-align: center;
        font-family: 'Lexend';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 44px 56px;

      .box {
        padding: 20px;
      }
    }
  }

  .sec-3 {
    h2 {
      color: #111827;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      text-align: left;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    p {
      color: #6b7280;
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }

    a {
      border-radius: 6px;
      background: #2563eb;
      /* shadow/sm */
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      color: var(--white, #fff);
      /* text-sm/leading-5/font-medium */
      //font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */

      &:hover {
        background: #2563eb;
        color: var(--white, #fff);
      }
    }
  }

  .sec-4 {
    // margin-bottom: 52px;

    h2 {
      color: #111827;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      text-align: left;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 36px; /* 150% */
      }
    }

    p {
      color: #6b7280;
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }

  .sec-5 {
    margin-top: 0;
    background: #111827;
    padding-top: 52px;
    padding-bottom: 52px;

    .title-area {
      margin-bottom: 44px;

      h2 {
        color: #f9fafb;
        text-align: center;

        /* Desktop/h3 */
        font-family: 'Lexend';
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px; /* 133.333% */

        @include media-breakpoint-down(sm) {
          font-size: 24px;
          line-height: 36px; /* 150% */
        }
      }

      p {
        color: #f9fafb;
        text-align: center;
        font-family: 'Lexend';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        width: 75%;
        margin: auto;
        margin-bottom: 20px;

        @include media-breakpoint-down(lg) {
          width: 90%;
        }
      }
    }

    .selectable-list {
      padding: 0 20px 0 20px;

      div {
        border-radius: 8px;
        background: #1f2937;
        border: 2px solid transparent;
        padding: 32px;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        h3 {
          color: #f9fafb;

          /* Desktop/h4 */
          font-family: 'Lexend';
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px; /* 150% */
          margin-bottom: 8px;
        }

        p {
          color: #f9fafb;

          /* Desktop/body */
          font-family: 'Lexend';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          margin-bottom: 0;
        }

        &.active {
          border: 2px solid #2563eb;
        }
      }

      @include media-breakpoint-down(md) {
        padding: 20px;
      }
    }
  }

  .sec-6 {
    margin-bottom: 52px;

    h2 {
      color: #111827;
      text-align: center;

      /* Desktop/h3 */
      font-family: 'Lexend';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 48px; /* 133.333% */
      margin-bottom: 20px;
      margin-top: 52px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px; /* 150% */
      }
    }

    .box {
      border-radius: 8px;
      background: #1f2937;
      display: flex;
      padding: 52px 32px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      min-height: 308px;

      .icon-wrapper {
        border-radius: 98px;
        background: radial-gradient(50% 50% at 50% 50%, #1d4ed8 0%, #374151 100%);
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        color: #93c5fd;
        text-align: center;
        /* Desktop/h4 */

        font-size: 24px;
        font-family: 'Noto Color Emoji', sans-serif;
        font-weight: 400;
        font-style: normal;
        line-height: 36px; /* 150% */
      }

      h3 {
        color: #f9fafb;
        text-align: center;
        /* Desktop/h5 */
        font-family: 'Lexend';
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 133.333% */
        margin: 0 auto 0 auto;

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      p {
        color: #f9fafb;
        text-align: center;

        /* Desktop/body */
        font-family: 'Lexend';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 0;
      }
    }
  }
}

.grow-with-louiseBot {
  background: #4167da;
  padding-top: 68px;
  padding-bottom: 68px;
  text-align: center;

  p {
    color: #eef1fd;
    text-align: center;
    /* Desktop/h3 */
    font-family: 'Lexend';
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
  }

  a {
    display: inline-flex;
    padding: 9px 17px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #111e45;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    color: #fff;
    /* text-sm/leading-5/font-medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.screen-container {
  max-width: 1280px;
  margin: auto;
}

// Ui refinements ===> EEC-5084
@import '../../components//home/base';
