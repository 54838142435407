.sec-5 {
  margin-top: 52px;
  margin-bottom: 52px;

  .navigation-item {
    border-radius: 99px;
    border: 1px solid #e5e7eb;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .testimonial-item {
    .stars {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-bottom: 20px;
    }

    p {
      color: #111827;

      /* Desktop/h4 */
      font-family: 'Lexend';
      font-size: 18px;
      font-style: normal;
      font-weight: 200;
      line-height: 36px; /* 150% */
      margin-bottom: 32px;

      @include media-breakpoint-down(md) {
        font-size: 18px;
        font-style: normal;
        font-weight: 200;
        line-height: 36px; /* 150% */
      }
    }

    .user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      div {
        span {
          display: block;

          &:first-child {
            color: #111827;

            /* Desktop/h6 */
            font-family: 'Lexend';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
          }

          &:last-child {
            color: #6b7280;

            /* Desktop/Caption */
            font-family: 'Lexend';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
    }
  }
}
