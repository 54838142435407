.home-page {
  .sec-2 {
    margin-top: 60px;
    margin-bottom: 72px;
    .title {
      h2 {
        overflow: hidden;
        color: #111827;
        text-align: center;
        /* Desktop/h3 */
        font-family: 'Lexend';
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px; /* 133.333% */

        &:before,
        &:after {
          background-color: #dfe3e6;
          content: '';
          display: inline-block;
          height: 1px;
          position: relative;
          vertical-align: middle;
          width: 50%;

          // @include media-breakpoint-down(md) {
          //   content: none;
          //   display: none;
          // }
        }

        &:before {
          right: 0.5em;
          margin-left: -50%;
        }

        &:after {
          left: 0.5em;
          margin-right: -50%;
        }

        @include media-breakpoint-down(md) {
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px; /* 150% */
        }
      }
    }
  }
}
